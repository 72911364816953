import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { ingredientsInterface } from '../../components/Recipes';

let units_of_time_selector =
    process.env.REACT_APP_FEATURE_UNITS_OF_TIME_SELECTOR === 'true';

interface PrepTimeProps {
    ingredients: ingredientsInterface;
}

export const PrepTime: React.FC<PrepTimeProps> = ({ ingredients }) => {
    const dispatch = useAppDispatch();

    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={
                openDialog &&
                dialogState === 60 &&
                focusedPrepItem.units !== null
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Things like marinades take a long time before they're
                    actually READY TO SERVE...
                    <br />
                    <br />
                    The{' '}
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.itemName}
                    </span>{' '}
                    start today, will be ready to serve as{' '}
                    <span style={{ color: 'blue' }}>
                        {Object.keys(ingredients).find(
                            (ingredient: string) =>
                                ingredients[ingredient].uuid ===
                                focusedPrepItem.ingredientUUID
                        )}
                    </span>{' '}
                    in:
                </DialogContentText>
                <FormGroup
                    aria-label="position"
                    row
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <TextField
                            id="filled-number"
                            label="Number"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={focusedPrepItem.leadTime}
                            variant="standard"
                            error={
                                Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                                Number(focusedPrepItem.leadTime) < 0
                            }
                            InputProps={{
                                endAdornment: !units_of_time_selector && (
                                    <InputAdornment position="end">
                                        Days
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        leadTime: event.target.value as string,
                                    })
                                );
                            }}
                        />
                        {(Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                            Number(focusedPrepItem.leadTime) < 0) && (
                            <FormHelperText id="component-error-text">
                                Only whole, non-negative values are allowed.
                            </FormHelperText>
                        )}
                    </FormControl>
                    {units_of_time_selector && (
                        <FormControl
                            variant="standard"
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                        >
                            <InputLabel id="select-unit-of-time-label">
                                Unit
                            </InputLabel>
                            <Select
                                labelId="select-unit-of-time-label"
                                id="select-unit-of-time"
                                value={focusedPrepItem.leadTimeUnits}
                                label="Unit"
                                onChange={(event) =>
                                    dispatch(
                                        updateFocusedPrepItem({
                                            leadTimeUnits: event.target
                                                .value as string,
                                        })
                                    )
                                }
                            >
                                {['Days'].map((option, idx) => {
                                    return (
                                        <MenuItem
                                            value={option}
                                            key={idx}
                                        >
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(
                            updateFocusedPrepItem(
                                {
                                    leadTime: '0',
                                    leadTimeUnits: units_of_time_selector
                                        ? ''
                                        : 'Days',
                                },
                                focusedPrepItem.scheduleType === 'As Needed'
                                    ? 3
                                    : 4
                            )
                        );
                    }}
                >
                    Back
                </Button>
                <Button
                    disabled={
                        focusedPrepItem.leadTime === '' ||
                        focusedPrepItem.leadTimeUnits === '' ||
                        Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                        Number(focusedPrepItem.leadTime) < 0
                    }
                    color={
                        Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                        Number(focusedPrepItem.leadTime) < 0
                            ? 'error'
                            : 'primary'
                    }
                    onClick={() => {
                        dispatch(updateFocusedPrepItem({}, 5));
                    }}
                    autoFocus
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};
