import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';

let units_of_time_selector =
    process.env.REACT_APP_FEATURE_UNITS_OF_TIME_SELECTOR === 'true';

interface SelfLifeProps {
    addToPrepList: (modifiedItem?: any, remove?: boolean) => void;
}

export const ShelfLife: React.FC<SelfLifeProps> = ({ addToPrepList }) => {
    const dispatch = useAppDispatch();

    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={
                openDialog &&
                dialogState === 6 &&
                focusedPrepItem.units !== null
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {selectedPrepList}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.ingredientName}
                    </span>{' '}
                    can be used for how long before going bad?
                </DialogContentText>
                <FormGroup
                    aria-label="position"
                    row
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <TextField
                            id="filled-number"
                            label="Number"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={focusedPrepItem.shelfLife}
                            variant="standard"
                            error={
                                Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                                Number(focusedPrepItem.shelfLife) < 0
                            }
                            InputProps={{
                                endAdornment: !units_of_time_selector && (
                                    <InputAdornment position="end">
                                        Days
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        shelfLife: event.target.value,
                                    })
                                );
                            }}
                        />
                        {(Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                            Number(focusedPrepItem.shelfLife) < 0) && (
                            <FormHelperText id="component-error-text">
                                Only whole, non-negative values are allowed.
                            </FormHelperText>
                        )}
                    </FormControl>
                    {units_of_time_selector && (
                        <FormControl
                            variant="standard"
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                        >
                            <InputLabel id="select-unit-of-time-label">
                                Unit
                            </InputLabel>
                            <Select
                                labelId="select-unit-of-time-label"
                                id="select-unit-of-time"
                                value={focusedPrepItem.shelfLifeUnits}
                                label="Unit"
                                onChange={(event) =>
                                    dispatch(
                                        updateFocusedPrepItem({
                                            shelfLifeUnits: event.target
                                                .value as string,
                                        })
                                    )
                                }
                            >
                                {['Days'].map((option, idx) => {
                                    return (
                                        <MenuItem
                                            value={option}
                                            key={idx}
                                        >
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(
                            updateFocusedPrepItem(
                                {
                                    shelfLife: '1',
                                    shelfLifeUnits: units_of_time_selector
                                        ? ''
                                        : 'Days',
                                },
                                5
                            )
                        );
                    }}
                >
                    Back
                </Button>
                <Button
                    disabled={
                        focusedPrepItem.shelfLife === '' ||
                        focusedPrepItem.shelfLifeUnits === '' ||
                        Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                        Number(focusedPrepItem.shelfLife) < 0
                    }
                    color={
                        Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                        Number(focusedPrepItem.shelfLife) < 0
                            ? 'error'
                            : 'primary'
                    }
                    onClick={() => {
                        addToPrepList();
                        dispatch(updateFocusedPrepItem({}, 7));
                    }}
                    autoFocus
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};
