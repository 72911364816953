import { recipe_state } from '../../../constants/redux_store_initializers';

export interface prepItem {
    id: string;
    itemName: string;
    ingredientCategory: string;
    ingredientName: string;
    ingredientUUID: string;
    scheduleType: string;
    units: string | null;
    leadTime: string;
    leadTimeUnits: string;
    shelfLife: string;
    shelfLifeUnits: string;
    prepSchedule: string;
    prepDays: boolean[];
    newUnit: string | null;
    newUnitConversion: string;
    section: string;
}

export interface RecipesInterface {
    prepLists: any;
    focusedPrepItem: prepItem;
    focusedPrepSection: string;
    selectedPrepSection: string;
    dialogState: number;
    openDialog: boolean;
    activeItem: string;
    selectedPrepList: string;
    prepLicense: number | null;
}

export const initialRecipesState: RecipesInterface = recipe_state;
