import React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { ingredientsInterface } from '../../components/Recipes';

interface UnitsProps {
    ingredients: ingredientsInterface;
}

export const Units: React.FC<UnitsProps> = ({ ingredients }) => {
    const dispatch = useAppDispatch();

    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={openDialog && dialogState === 5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">Unit of Measure</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    My recipes use{' '}
                    <span style={{ color: 'blue' }}>
                        {
                            Object.values(ingredients).find(
                                (ingredient: any) =>
                                    ingredient.uuid ===
                                    focusedPrepItem.ingredientUUID
                            )?.units
                        }
                    </span>{' '}
                    to measure{' '}
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.ingredientName}
                    </span>
                    .
                    <br />
                    On my prep sheet, I'd like{' '}
                    <span style={{ color: 'blue' }}>{selectedPrepList}</span> to
                    be in:
                </DialogContentText>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <ButtonGroup size="large">
                        <Button
                            key={
                                Object.values(ingredients).find(
                                    (ingredient: any) =>
                                        ingredient.uuid ===
                                        focusedPrepItem.ingredientUUID
                                )?.units
                            }
                            variant={
                                focusedPrepItem.units ===
                                    Object.values(ingredients).find(
                                        (ingredient: any) =>
                                            ingredient.uuid ===
                                            focusedPrepItem.ingredientUUID
                                    )?.units ?? false
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem(
                                        {
                                            units:
                                                Object.values(ingredients).find(
                                                    (ingredient: any) =>
                                                        ingredient.uuid ===
                                                        focusedPrepItem.ingredientUUID
                                                )?.units ?? '',
                                        },
                                        6
                                    )
                                );
                            }}
                        >
                            {`Use "${
                                Object.values(ingredients).find(
                                    (ingredient: any) =>
                                        ingredient.uuid ===
                                        focusedPrepItem.ingredientUUID
                                )?.units
                            }"`}
                        </Button>
                        <Button
                            key={null}
                            variant={
                                focusedPrepItem.units === null
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem({ units: null }, 6)
                                );
                            }}
                        >
                            Convert to something else
                        </Button>
                    </ButtonGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(
                            updateFocusedPrepItem(
                                { units: '' },
                                focusedPrepItem.scheduleType === 'As Needed'
                                    ? 3
                                    : 4
                            )
                        );
                    }}
                >
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    );
};
