import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

export function DialogUnsavedChanges(
    openDialog: boolean,
    dialogState: number,
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setDialogState: React.Dispatch<React.SetStateAction<number>>,
    setNewTabValue: (newTabValue: number) => void,
    nextTabValue: number | undefined,
    setNextTabValue: React.Dispatch<React.SetStateAction<number | undefined>>
): React.ReactNode {
    const closeDialog = () => {
        setOpenDialog(false);
        setDialogState(0);
        setNextTabValue(undefined);
    };

    return (
        <Dialog
            maxWidth={false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={openDialog && dialogState === -8008135}
            onClose={(event: object, reason: string) => {
                if (reason === 'backdropClick') return;
            }}
        >
            {/* <DialogTitle id="alert-dialog-title">Congrats!</DialogTitle> */}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    You have unsaved changes that will be lost.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        closeDialog();
                    }}
                >
                    Continue Editing
                </Button>
                <Button
                    onClick={() => {
                        setNewTabValue(nextTabValue!);
                        closeDialog();
                    }}
                >
                    Leave Page
                </Button>
            </DialogActions>
        </Dialog>
    );
}
