import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppSelector } from '../../../../redux/hooks';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
    selectPrepLists,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';

interface SideSummaryProps {}

export const SideSummary: React.FC<SideSummaryProps> = () => {
    const prepLists = useAppSelector(selectPrepLists);
    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            open={openDialog && dialogState > 0}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ width: `${ratio * 100}%` }}
        >
            <DialogContent>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography fontSize="22px">
                        {focusedPrepItem.section}
                    </Typography>
                    {prepLists &&
                        selectedPrepList &&
                        prepLists[selectedPrepList][
                            focusedPrepItem.section
                        ]?.map((item: any, idx: number) => {
                            return (
                                <Typography key={idx}>
                                    {item.item_name}
                                </Typography>
                            );
                        })}
                </Box>
            </DialogContent>
        </Dialog>
    );
};
