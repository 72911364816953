import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepSection,
    updateFocusedPrepSection,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepSection,
    selectOpenDialog,
    selectSelectedPrepList,
    selectSelectedPrepSection,
} from '../../../../redux/reducers/recipes/recipesSelectors';

interface EditSectionProps {
    addSection: (
        newSection: string,
        currentSection?: string | null,
        remove?: boolean
    ) => void;
}

export const EditSection: React.FC<EditSectionProps> = ({ addSection }) => {
    const dispatch = useAppDispatch();

    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);
    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepSection = useAppSelector(selectFocusedPrepSection);
    const selectedPrepSection = useAppSelector(selectSelectedPrepSection);

    return (
        <Dialog
            fullWidth
            open={openDialog && dialogState === -5}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepSection());
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {selectedPrepList}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Edit your section name.
                </DialogContentText>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <InputLabel>Section</InputLabel>
                    <Input
                        value={focusedPrepSection}
                        onChange={(event) => {
                            dispatch(
                                updateFocusedPrepSection(event.target.value)
                            );
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(clearFocusedPrepSection());
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="error"
                    onClick={() => {
                        addSection(
                            focusedPrepSection,
                            selectedPrepSection,
                            true
                        );
                        dispatch(clearFocusedPrepSection());
                    }}
                >
                    Delete
                </Button>
                <Button
                    disabled={
                        focusedPrepSection === '' ||
                        focusedPrepSection === selectedPrepSection
                    }
                    onClick={() => {
                        addSection(
                            focusedPrepSection,
                            selectedPrepSection,
                            false
                        );
                        dispatch(clearFocusedPrepSection());
                    }}
                    autoFocus
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
