import React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
} from '../../../../redux/reducers/recipes/recipesSelectors';

interface PrepScheduleProps {}

export const PrepSchedule: React.FC<PrepScheduleProps> = () => {
    const dispatch = useAppDispatch();

    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={
                openDialog &&
                dialogState === 4 &&
                focusedPrepItem.scheduleType === 'Batches'
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title"></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Since you prep this item in batches, what does the prep schedule look like?`}
                </DialogContentText>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <ButtonGroup size="large">
                        <Button
                            key="schedule"
                            variant={
                                focusedPrepItem.prepSchedule === 'schedule'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem(
                                        { prepSchedule: 'schedule' },
                                        4.1
                                    )
                                );
                            }}
                        >
                            Prep is schedule for Specific days
                        </Button>
                        <Button
                            key="shelf life"
                            variant={
                                focusedPrepItem.prepSchedule === 'shelf life'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem(
                                        { prepSchedule: 'shelf life' },
                                        60
                                    )
                                );
                            }}
                        >
                            Any day works
                        </Button>
                    </ButtonGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(
                            updateFocusedPrepItem({ prepSchedule: '' }, 3)
                        );
                    }}
                >
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    );
};
