import { useEffect, useState } from 'react';

import { Divider, Typography } from '@mui/material';

import { BoxPrepListSection } from '../../../../components/StyledMUI/StyledBox';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { selectEnforceLicenseLimit } from '../../../../redux/reducers/featureSwitch/featureSwitchSelectors';
import { setFocusedPrepSection } from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectPrepLicenses,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { PrepListSectionProps } from './IPrepListTab';
import { PrepListAddItem } from './PrepListAddItem';
import { PrepListItemRow } from './PrepListItemRow';
import { PrepListLabelRow } from './PrepListLabelRow';

/**
 * Renders a section of the prep list with its corresponding items.
 * @param section - The section of the prep list.
 * @param prepList - The list of items in the prep list.
 */
export const PrepListSection: React.FC<PrepListSectionProps> = ({
    section,
    prepList,
}) => {
    const dispatch = useAppDispatch();

    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const prepLicenses = useAppSelector(selectPrepLicenses);
    const enforceLicenseLimit = useAppSelector(selectEnforceLicenseLimit);

    const [consumedLicenses, setConsumedLicenses] = useState<number>(0);

    /**
     * useEffect hook that updates the number of consumed licenses based on the selected prep list.
     * @returns {void}
     */
    useEffect(() => {
        if (selectedPrepList === '') return;
        setConsumedLicenses(
            Object.keys(prepList).reduce((currVal, section) => {
                return currVal + prepList[section].length;
            }, 0)
        );
    }, [selectedPrepList, prepList]);

    const ignoreLicenseLimit = !enforceLicenseLimit || (prepLicenses ?? -1) < 0;
    const outOfLicenses = consumedLicenses >= (prepLicenses ?? 0);
    const hidePrepListAddItem = !ignoreLicenseLimit && outOfLicenses;

    return (
        <BoxPrepListSection>
            <Divider variant="black">
                <Typography
                    variant="prepListSectionHeader"
                    onClick={() => {
                        dispatch(setFocusedPrepSection(section, -5));
                    }}
                >
                    {section}
                </Typography>
            </Divider>
            {prepList[section].length !== 0 && <PrepListLabelRow />}
            {prepList[section].map((item: any) => {
                if (!item) return null;
                return (
                    <PrepListItemRow
                        key={item.id}
                        item={item}
                        section={section}
                    />
                );
            })}
            {!hidePrepListAddItem && <PrepListAddItem section={section} />}
        </BoxPrepListSection>
    );
};
