import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { ingredientsInterface } from '../../components/Recipes';

interface UnitConversionProps {
    ingredients: ingredientsInterface;
}

export const UnitConversion: React.FC<UnitConversionProps> = ({
    ingredients,
}) => {
    const dispatch = useAppDispatch();

    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    const handleClose = useCallback(
        (_: React.SyntheticEvent, reason: string) => {
            if (reason === 'backdropClick') return;
            dispatch(clearFocusedPrepItem());
        },
        [dispatch]
    );

    const handleNewUnitChange = useCallback(
        (event: any) => {
            dispatch(updateFocusedPrepItem({ newUnit: event.target.value }));
        },
        [dispatch]
    );

    const handleBackClick = useCallback(() => {
        dispatch(updateFocusedPrepItem({}, 5));
    }, [dispatch]);

    const handleNextClick = useCallback(() => {
        dispatch(
            updateFocusedPrepItem({ units: focusedPrepItem.newUnit }, 6.1)
        );
    }, [dispatch, focusedPrepItem.newUnit]);

    const findIngredientUnits = useCallback(
        (uuid: string) => {
            return Object.values(ingredients).find(
                (ingredient: any) => ingredient.uuid === uuid
            )?.units;
        },
        [ingredients]
    );

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={
                openDialog &&
                dialogState === 6 &&
                focusedPrepItem.units === null
            }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={handleClose}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {selectedPrepList}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    For{' '}
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.itemName}
                    </span>{' '}
                    change the units from{' '}
                    <span style={{ color: 'blue' }}>
                        {findIngredientUnits(focusedPrepItem.ingredientUUID)}
                    </span>{' '}
                    to:
                </DialogContentText>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <InputLabel>{focusedPrepItem.itemName} Units</InputLabel>
                    <Input
                        value={focusedPrepItem.newUnit}
                        onChange={handleNewUnitChange}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleBackClick}>Back</Button>
                <Button
                    disabled={focusedPrepItem.newUnit === ''}
                    autoFocus
                    onClick={handleNextClick}
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};
