import React, { useEffect, useState } from 'react';

import isEqual from 'lodash.isequal';
import { v4 as uuid } from 'uuid';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import {
    deletePrepSheets,
    getIngredients,
    getMenu,
    getMenuItems,
    getPrepSheets,
    postMenu,
    updateIngredients,
    updatePrepSheets,
} from '../../../api';
import { deleteIngredients } from '../../../api/functions/deleteIngredients';
import { updateMenuItems } from '../../../api/functions/updateMenuItems';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
    clearFocusedPrepItem,
    setPrepLicense,
    setPrepLists,
} from '../../../redux/reducers/recipes/recipesActions';
import {
    selectFocusedPrepItem,
    selectPrepLists,
    selectSelectedPrepList,
} from '../../../redux/reducers/recipes/recipesSelectors';
import { setToastContext } from '../../../redux/reducers/toast/toastActions';
import { ToastInterface } from '../../../redux/reducers/toast/toastState';
import genericDialogs from '../dialogs/genericDialogs';
import ingredientDialogs from '../dialogs/ingredientDialogs';
import modifierDialogs from '../dialogs/modifierDialogs';
import preplistDialogs from '../dialogs/preplistDialogs';
import { ConceptNoAccess } from './ConceptNoAccess';
import { IngredientTab } from './IngredientTab';
import { PrepListTab } from './PrepListTab/PrepListTab';
import { RecipeModifierTab } from './RecipeModifierTab/RecipeModifierTab';
import { RecipeTab } from './RecipeTab';
import { TabPanel, a11yProps } from './TabPanel';

export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface menuItemsInterface {
    [key: string]: any;
}

export interface ingredientsInterface {
    [key: string]: any;
}

let new_data_format = process.env.REACT_APP_FEATURE_NEW_DATA_FORMAT === 'true';

let patricks_secret_lair =
    process.env.REACT_APP_PATRICKS_SUPER_SECRET_FUCK_THIS_SHIT_FEATURES ===
    'true';

export function Recipes({ user_details, concept, store }: any) {
    const dispatch = useAppDispatch();

    // Prep List Tab Selectors
    const prepLists = useAppSelector(selectPrepLists);
    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);

    const selectedCategory = focusedPrepItem.section;

    // State values, most to be depreciated
    const [tabValue, setTabValue] = useState<number>(0);
    const [nextTabValue, setNextTabValue] = useState<number | undefined>(
        undefined
    );
    const [expanded, setExpanded] = useState({});
    const [dialogState, setDialogState] = useState<number>(1);
    const [openDialog, setOpenDialog] = useState(false);
    const [editName, setEditName] = useState('');
    const [menuItems, setMenuItems] = useState<menuItemsInterface>({});
    const [menuItemsEgress, setMenuItemsEgress] = useState<menuItemsInterface>(
        {}
    );
    const [ingredients, setIngredients] = useState<ingredientsInterface>({});
    const [usageDates, setUsageDates] = useState<any>({});
    const [ingredientsEgress, setIngredientsEgress] =
        useState<ingredientsInterface>({});
    const [categories, setCategories] = useState<string[]>([
        'Bottled Beverages',
        'Bread',
        'Coffee/Tea',
        'Dairy',
        'Dry Goods',
        'Grocery',
        'Meat',
        'Pastry',
        'Produce',
        'Seafood',
    ]);
    const [idToIdx, setIdToIdx] = useState<string>('');
    const [activeItem, setActiveItem] = useState<string>('');
    const [activeItemModifier, setActiveItemModifier] = useState<string>('');
    const [activeItemModifierOption, setActiveItemModifierOption] =
        useState<string>('');
    const [copyRegIng, setCopyRegIng] = useState<string[]>([]);
    const [copyRegIngUuid, setCopyRegIngUuid] = useState<string[]>([]);
    const [copyRegQty, setCopyRegQty] = useState<string[]>([]);
    const [loadingSpinner, setLoadingSpinner] = useState<boolean>(true);
    const [duplicateError, setDuplicateError] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [filteredMenuItems, setFilteredMenuItems] =
        useState<menuItemsInterface>({});
    const [pageLock, setPageLock] = useState<boolean>(false);
    const [modifierIngredients, setModifierIngredients] = useState<any | any[]>(
        {}
    );
    const [modifierIngredientsOnLoad, setModifierIngredientsOnLoad] = useState<
        any | any[]
    >({});
    const [modifierIngredientsArr, setModifierIngredientsArr] = useState<
        string[][]
    >([]);
    const [modifierIngredientsArrOnLoad, setModifierIngredientsArrOnLoad] =
        useState<string[][]>([]);
    const [
        modifierIngredientsArrMiddleLoad,
        setModifierIngredientsArrMiddleLoad,
    ] = useState<string[][]>([]);
    const [modifiedMenuItems, setModifiedMenuItems] = useState<any[]>([]);
    const [modifiedQtyValue, setModifiedQtyValue] = useState<string>('');
    const [dialogAutocompleteInputValue, setDialogAutocompleteInputValue] =
        useState<string>('');
    const [selectedModIng, setSelectedModIng] = useState<string[]>([]);

    const searchBarRef = React.useRef<HTMLInputElement>();

    /**
     * This function iterates over the `menuItems` object and constructs a `modifier_object` based on certain conditions.
     * The `modifier_object` is a nested object that stores information about menu items, modifiers, and options.
     *
     * @param menuItems - The menu items object to iterate over.
     * @returns The constructed `modifier_object` with menu items, modifiers, and options.
     */
    let modifier_object: any = {};
    Object.entries(menuItems).forEach((menuItem: [string, any]) => {
        const [key1, value1] = menuItem;
        if (value1.level_2 && value1.level_2.length !== 0) {
            Object.entries(value1.level_2).forEach(
                (modifier: [string, any]) => {
                    const [key2, value2] = modifier;
                    modifier_object[key2]
                        ? modifier_object[key2]['Items'].push(key1)
                        : (modifier_object[key2] = {
                              Items: [key1],
                              Options: [],
                          });
                    if (value2.level_3 && value2.level_3.length !== 0) {
                        Object.keys(value2.level_3).forEach((key3) => {
                            if (
                                Object.keys(modifier_object[key2]['Options'])
                                    .length === 0 ||
                                !Object.keys(
                                    modifier_object[key2]['Options']
                                ).includes(key3)
                            ) {
                                modifier_object[key2]['Options'][key3] = [key1];
                            } else if (
                                Object.keys(
                                    modifier_object[key2]['Options']
                                ).includes(key3)
                            ) {
                                modifier_object[key2]['Options'][key3].push(
                                    key1
                                );
                            }
                        });
                    }
                }
            );
        }
    });

    /**
     * useEffect hook that filters menu items based on the provided filter value.
     * Updates the filteredMenuItems state with the filtered results.
     *
     * @param {string} filter - The filter value to apply on the menu items.
     * @param {object} menuItemsEgress - The menu items object to filter.
     */
    useEffect(() => {
        setFilteredMenuItems(
            Object.keys(menuItemsEgress)
                .filter(
                    (objKey) =>
                        objKey.toLowerCase().includes(filter.toLowerCase()) ||
                        Object.keys(
                            menuItemsEgress[objKey]?.level_2 ?? {}
                        ).filter(
                            (subKey) =>
                                subKey
                                    .toLowerCase()
                                    .includes(filter.toLowerCase()) ||
                                Object.keys(
                                    menuItemsEgress[objKey]?.level_2[subKey]
                                        ?.level_3 ?? {}
                                ).filter((finalKey) =>
                                    finalKey
                                        .toLowerCase()
                                        .includes(filter.toLowerCase())
                                ).length > 0
                        ).length > 0
                )
                .reduce((cur, key) => {
                    return Object.assign(cur, { [key]: menuItemsEgress[key] });
                }, {}) ?? {}
        );
    }, [filter, menuItemsEgress]);

    /**
     * Executes the effect when `new_data_format` and `loadingSpinner` change.
     * Fetches menu items and prep sheets based on the `concept` value.
     * Updates the state with the fetched data.
     * Logs the menu items and prep sheets if `REACT_APP_DEBUG` is set to 'true'.
     * @param {boolean} new_data_format - The flag indicating the data format.
     * @param {boolean} loadingSpinner - The flag indicating the loading state.
     * @param {string} concept - The concept value.
     * @param {function} dispatch - The dispatch function from the Redux store.
     */
    useEffect(() => {
        if (new_data_format && !loadingSpinner) {
            getMenuItems(concept)
                .then((results) => {
                    let NewMenuItems = Object.keys(results.menu_items)
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        })
                        .reduce((obj: any, key: string) => {
                            obj[key] = results.menu_items[key];
                            return obj;
                        }, {});
                    process.env.REACT_APP_DEBUG === 'true' &&
                        console.log('Menu Items: ', NewMenuItems);
                    setMenuItems(JSON.parse(JSON.stringify(NewMenuItems)));
                    setMenuItemsEgress(
                        JSON.parse(JSON.stringify(NewMenuItems))
                    );
                })
                .catch((err) => {
                    console.error(err);
                });
            getPrepSheets(concept)
                .then((results) => {
                    process.env.REACT_APP_DEBUG === 'true' &&
                        console.log('Prep Sheets: ', results);
                    if (results && results.prep_lists) {
                        dispatch(setPrepLists(results.prep_lists));
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [loadingSpinner, concept, dispatch]);

    const setToastState = (toastContext: ToastInterface) => {
        dispatch(setToastContext(toastContext));
    };

    const addSection = (
        newSection: string,
        currentSection: string | null = null,
        remove: boolean = false
    ) => {
        let prepListsCopy: any = JSON.parse(JSON.stringify(prepLists));
        if (currentSection) {
            if (remove) {
                delete prepListsCopy[selectedPrepList][currentSection];
            } else {
                prepListsCopy[selectedPrepList][newSection] = JSON.parse(
                    JSON.stringify(
                        prepListsCopy[selectedPrepList][currentSection]
                    )
                );
                delete prepListsCopy[selectedPrepList][currentSection];
            }
        } else {
            prepListsCopy[selectedPrepList][newSection] = [];
        }

        const newPrepListsCopy = JSON.parse(JSON.stringify(prepListsCopy));
        dispatch(setPrepLists(newPrepListsCopy));

        if (new_data_format) {
            deletePrepSheets({
                id: idToIdx,
                prepListsSections: currentSection
                    ? [currentSection]
                    : currentSection,
            }).then(() => {
                !remove &&
                    updatePrepSheets({
                        id: idToIdx,
                        prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                    })
                        .then(() => {
                            setToastState({
                                toastMessage: 'Section added to prep list!',
                                toastType: 'success',
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                            setToastState({
                                toastMessage:
                                    'Failed to add section to prep list.',
                                toastType: 'error',
                            });
                            return;
                        });
            });
        } else {
            postMenu({
                id: idToIdx,
                ingredients: ingredients,
                menuItems: menuItems,
                categories: categories,
                prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
            })
                .then(() => {
                    setToastState({
                        toastMessage: 'Section added to prep list!',
                        toastType: 'success',
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setToastState({
                        toastMessage: 'Failed to add section to prep list.',
                        toastType: 'error',
                    });
                    return;
                });
        }
    };

    const addToPrepList = (
        modifiedItem: any = null,
        remove: boolean = false,
        duplicate: boolean = false
    ) => {
        let prepListsCopy: any = JSON.parse(JSON.stringify(prepLists));
        if (modifiedItem === null) {
            prepListsCopy[selectedPrepList][selectedCategory].push({
                id: uuid(),
                item_name: focusedPrepItem.itemName,
                ingredient_category: focusedPrepItem.ingredientCategory, // TO REMOVE
                ingredient_name: focusedPrepItem.ingredientName, // TO REMOVE
                ingredient_uuid: focusedPrepItem.ingredientUUID,
                schedule_type: focusedPrepItem.scheduleType,
                units:
                    focusedPrepItem.newUnit !== ''
                        ? focusedPrepItem.newUnit
                        : focusedPrepItem.units,
                shelf_life: focusedPrepItem.shelfLife,
                shelf_life_units: focusedPrepItem.shelfLifeUnits,
                lead_time: focusedPrepItem.leadTime,
                lead_time_units: focusedPrepItem.leadTimeUnits,
                prep_schedule: focusedPrepItem.prepSchedule,
                new_unit: focusedPrepItem.newUnit,
                new_unit_conversion: focusedPrepItem.newUnitConversion,
                prep_days: focusedPrepItem.prepDays,
            });
            const newPrepListsCopy = JSON.parse(JSON.stringify(prepListsCopy));
            dispatch(setPrepLists(newPrepListsCopy));

            (new_data_format
                ? updatePrepSheets({
                      id: idToIdx,
                      prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                  })
                : postMenu({
                      id: idToIdx,
                      ingredients: ingredients,
                      menuItems: menuItems,
                      categories: categories,
                      prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                  })
            )
                .then(() => {
                    setToastState({
                        toastMessage: 'Item added to prep list!',
                        toastType: 'success',
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setToastState({
                        toastMessage: 'Failed to add item.',
                        toastType: 'error',
                    });
                    return;
                });
        } else if (remove) {
            let index = prepListsCopy[selectedPrepList][
                selectedCategory
            ].findIndex((item: any) => item.id === modifiedItem);
            prepListsCopy[selectedPrepList][selectedCategory].splice(index, 1);

            const newPrepListsCopy = JSON.parse(JSON.stringify(prepListsCopy));
            dispatch(setPrepLists(newPrepListsCopy));

            (new_data_format
                ? updatePrepSheets({
                      id: idToIdx,
                      prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                  })
                : postMenu({
                      id: idToIdx,
                      ingredients: ingredients,
                      menuItems: menuItems,
                      categories: categories,
                      prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                  })
            )
                .then(() => {
                    setToastState({
                        toastMessage: 'Item added to prep list!',
                        toastType: 'success',
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setToastState({
                        toastMessage: 'Failed to add item.',
                        toastType: 'error',
                    });
                    return;
                });
        } else {
            let index: number;
            if (duplicate) {
                index =
                    prepListsCopy[selectedPrepList][selectedCategory].length;
            } else {
                index = prepListsCopy[selectedPrepList][
                    selectedCategory
                ].findIndex((item: any) => item.id === modifiedItem);
            }
            prepListsCopy[selectedPrepList][selectedCategory][index] = {
                id: !duplicate ? modifiedItem : uuid(),
                item_name: focusedPrepItem.itemName,
                ingredient_category: focusedPrepItem.ingredientCategory, // TO REMOVE
                ingredient_name: focusedPrepItem.ingredientName, // TO REMOVE
                ingredient_uuid: focusedPrepItem.ingredientUUID,
                schedule_type: focusedPrepItem.scheduleType,
                units: focusedPrepItem.units,
                shelf_life: focusedPrepItem.shelfLife,
                shelf_life_units: focusedPrepItem.shelfLifeUnits,
                lead_time: focusedPrepItem.leadTime,
                lead_time_units: focusedPrepItem.leadTimeUnits,
                prep_schedule: focusedPrepItem.prepSchedule,
                new_unit: focusedPrepItem.newUnit,
                new_unit_conversion: focusedPrepItem.newUnitConversion,
                prep_days: focusedPrepItem.prepDays,
            };
            const newPrepListsCopy = JSON.parse(JSON.stringify(prepListsCopy));
            dispatch(setPrepLists(newPrepListsCopy));

            (new_data_format
                ? updatePrepSheets({
                      id: idToIdx,
                      prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                  })
                : postMenu({
                      id: idToIdx,
                      ingredients: ingredients,
                      menuItems: menuItems,
                      categories: categories,
                      prepLists: JSON.parse(JSON.stringify(prepListsCopy)),
                  })
            )
                .then(() => {
                    setToastState({
                        toastMessage: 'Item added to prep list!',
                        toastType: 'success',
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setToastState({
                        toastMessage: 'Failed to add item.',
                        toastType: 'error',
                    });
                    return;
                });
        }
    };

    const setConcept = (concept: string) => {
        setIdToIdx(concept);
        if (new_data_format) {
            handleIngredientRefresh();
        } else {
            getMenu(concept)
                .then((results) => {
                    console.log('forecastable items: ', results.ingredients);
                    let jsonified_original_results = JSON.stringify(results);
                    let menuItemsCopy = JSON.parse(
                        JSON.stringify(results.menuItems)
                    );
                    Object.entries(menuItemsCopy).forEach(
                        ([key, value]: any) => {
                            if (!('ingredients_uuid' in value)) {
                                menuItemsCopy[key].ingredients_uuid =
                                    menuItemsCopy[key].ingredients
                                        .map((ingredient: string) => {
                                            if (
                                                results.ingredients[ingredient]
                                            ) {
                                                return results.ingredients[
                                                    ingredient
                                                ].uuid;
                                            } else {
                                                return undefined;
                                            }
                                        })
                                        .filter((element: any) => {
                                            return element !== undefined;
                                        });
                            } else if (
                                'ingredients_uuid' in value &&
                                'ingredients' in value &&
                                value.ingredients_uuid.length !==
                                    value.ingredients.length
                            ) {
                                menuItemsCopy[key].ingredients =
                                    value.ingredients_uuid.map(
                                        (ingredient_uuid: string) => {
                                            return Object.keys(
                                                results.ingredients
                                            ).find(
                                                (ingredient) =>
                                                    results.ingredients[
                                                        ingredient
                                                    ].uuid === ingredient_uuid
                                            );
                                        }
                                    );
                            }
                            if (
                                value.level_2 &&
                                Object.keys(value.level_2).length
                            ) {
                                Object.entries(value.level_2).forEach(
                                    ([key_2, value_2]: any) => {
                                        if (!('ingredients_uuid' in value_2)) {
                                            menuItemsCopy[key].level_2[
                                                key_2
                                            ].ingredients_uuid = menuItemsCopy[
                                                key
                                            ].level_2[key_2].ingredients
                                                .map((ingredient: string) => {
                                                    if (
                                                        results.ingredients[
                                                            ingredient
                                                        ]
                                                    ) {
                                                        return results
                                                            .ingredients[
                                                            ingredient
                                                        ].uuid;
                                                    } else {
                                                        return undefined;
                                                    }
                                                })
                                                .filter((element: any) => {
                                                    return (
                                                        element !== undefined
                                                    );
                                                });
                                        } else if (
                                            'ingredients_uuid' in value_2 &&
                                            'ingredients' in value_2 &&
                                            value_2.ingredients_uuid.length !==
                                                value_2.ingredients.length
                                        ) {
                                            menuItemsCopy[key].level_2[
                                                key_2
                                            ].ingredients =
                                                value_2.ingredients_uuid.map(
                                                    (
                                                        ingredient_uuid: string
                                                    ) => {
                                                        return Object.keys(
                                                            results.ingredients
                                                        ).find(
                                                            (ingredient) =>
                                                                results
                                                                    .ingredients[
                                                                    ingredient
                                                                ].uuid ===
                                                                ingredient_uuid
                                                        );
                                                    }
                                                );
                                        }
                                        if (
                                            value_2.level_3 &&
                                            Object.keys(value_2.level_3).length
                                        ) {
                                            Object.entries(
                                                value_2.level_3
                                            ).forEach(
                                                ([key_3, value_3]: any) => {
                                                    if (
                                                        !(
                                                            'ingredients_uuid' in
                                                            value_3
                                                        )
                                                    ) {
                                                        menuItemsCopy[
                                                            key
                                                        ].level_2[
                                                            key_2
                                                        ].level_3[
                                                            key_3
                                                        ].ingredients_uuid =
                                                            menuItemsCopy[
                                                                key
                                                            ].level_2[
                                                                key_2
                                                            ].level_3[
                                                                key_3
                                                            ].ingredients
                                                                .map(
                                                                    (
                                                                        ingredient: string
                                                                    ) => {
                                                                        if (
                                                                            results
                                                                                .ingredients[
                                                                                ingredient
                                                                            ]
                                                                        ) {
                                                                            return results
                                                                                .ingredients[
                                                                                ingredient
                                                                            ]
                                                                                .uuid;
                                                                        } else {
                                                                            return undefined;
                                                                        }
                                                                    }
                                                                )
                                                                .filter(
                                                                    (
                                                                        element: any
                                                                    ) => {
                                                                        return (
                                                                            element !==
                                                                            undefined
                                                                        );
                                                                    }
                                                                );
                                                    } else if (
                                                        'ingredients_uuid' in
                                                            value_3 &&
                                                        'ingredients' in
                                                            value_3 &&
                                                        value_3.ingredients_uuid
                                                            .length !==
                                                            value_3.ingredients
                                                                .length
                                                    ) {
                                                        menuItemsCopy[
                                                            key
                                                        ].level_2[
                                                            key_2
                                                        ].level_3[
                                                            key_3
                                                        ].ingredients =
                                                            value_3.ingredients_uuid.map(
                                                                (
                                                                    ingredient_uuid: string
                                                                ) => {
                                                                    return Object.keys(
                                                                        results.ingredients
                                                                    ).find(
                                                                        (
                                                                            ingredient
                                                                        ) =>
                                                                            results
                                                                                .ingredients[
                                                                                ingredient
                                                                            ]
                                                                                .uuid ===
                                                                            ingredient_uuid
                                                                    );
                                                                }
                                                            );
                                                    }
                                                }
                                            );
                                        }
                                    }
                                );
                            }
                        }
                    );
                    let altered_results = {
                        ...results,
                        menuItems: menuItemsCopy,
                    };
                    console.log('RESULTS: ', results);
                    console.log('ALTERED RESULTS: ', altered_results);
                    if (
                        JSON.stringify(altered_results) !==
                        jsonified_original_results
                    ) {
                        postMenu({
                            id: concept,
                            ingredients: results.ingredients,
                            menuItems: menuItemsCopy,
                            categories: results.categories,
                            prepLists: results.prepLists,
                        })
                            .then(() => {
                                setToastState({
                                    toastMessage: 'Reformatted data!',
                                    toastType: 'success',
                                });
                            })
                            .catch((err) => {
                                console.error(err);
                                setToastState({
                                    toastMessage: 'Failed to reform data.',
                                    toastType: 'error',
                                });
                                return;
                            });
                        results = altered_results;
                    }
                    let NewMenuItems = Object.keys(results.menuItems)
                        .sort((a, b) => {
                            return a.localeCompare(b);
                        })
                        .reduce((obj: any, key: string) => {
                            obj[key] = results.menuItems[key];
                            return obj;
                        }, {});
                    setMenuItems(JSON.parse(JSON.stringify(NewMenuItems)));
                    setMenuItemsEgress(
                        JSON.parse(JSON.stringify(NewMenuItems))
                    );
                    process.env.REACT_APP_DEBUG === 'true' &&
                        console.log(
                            'Recipes: ',
                            Object.keys(results.menuItems)
                                .sort((a, b) => {
                                    return a.localeCompare(b);
                                })
                                .reduce((obj: any, key: string) => {
                                    obj[key] = results.menuItems[key];
                                    return obj;
                                }, {})
                        );
                    setCategories(
                        results.categories.sort((a: string, b: string) => {
                            return a.localeCompare(b);
                        })
                    );
                    setIngredients(
                        Object.keys(results.ingredients)
                            .sort((a, b) => {
                                return a.localeCompare(b);
                            })
                            .reduce((obj: any, key: string) => {
                                obj[key] = results.ingredients[key];
                                return obj;
                            }, {})
                    );
                    setIngredientsEgress(
                        JSON.parse(JSON.stringify(results.ingredients))
                    );
                    if (results && results.prepLists) {
                        dispatch(setPrepLists(results.prepLists));
                    }
                    setLoadingSpinner(false);
                })
                .catch((err) => {
                    console.error(err);
                    setLoadingSpinner(false);
                });
        }
    };

    useEffect(() => {
        setActiveItem('');
        setActiveItemModifier('');
        setActiveItemModifierOption('');
        setMenuItems({});
        setMenuItemsEgress({});
        setFilteredMenuItems({});
        setFilter('');
        setCategories([]);
        setIngredients({});
        setIngredientsEgress({});
        dispatch(setPrepLists({}));
        setExpanded({});
        setDialogState(1);
        setOpenDialog(false);
        setEditName('');
        dispatch(clearFocusedPrepItem());
        setLoadingSpinner(true);
        let temp_id =
            user_details &&
            user_details.concepts &&
            user_details.concepts.includes(concept)
                ? concept
                : null;
        setIdToIdx(temp_id);
        if (temp_id) {
            setConcept(temp_id);
        } else {
            concept && setLoadingSpinner(false);
        }
        dispatch(
            setPrepLicense(
                user_details && user_details.licenses
                    ? Number(user_details.licenses)
                    : null
            )
        );
    }, [user_details, concept, dispatch]);

    const setNewTabValue = (newTabValue: number) => {
        setActiveItem('');
        setActiveItemModifier('');
        setActiveItemModifierOption('');
        setTabValue(newTabValue);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (tabValue === newValue) return;

        if (
            (tabValue === 1 &&
                JSON.stringify(menuItems) !==
                    JSON.stringify(menuItemsEgress)) ||
            (tabValue === 3 &&
                (!isEqual(modifierIngredientsOnLoad, modifierIngredients) ||
                    !isEqual(
                        modifierIngredientsArrOnLoad,
                        modifierIngredientsArr
                    )))
        ) {
            setOpenDialog(true);
            setDialogState(-8008135);
            setNextTabValue(newValue);
            return;
        }

        setNewTabValue(newValue);
    };

    const handleSelectChange = (
        event: SelectChangeEvent,
        index: number,
        newValue?: any
    ) => {
        if (newValue) {
            event.target.value = newValue.value;
        }
        let tempMenuItems = menuItemsEgress;
        // TODO: Add uuid based indexing to this function
        if (
            !!activeItem &&
            !!activeItemModifier &&
            !!activeItemModifierOption
        ) {
            if (index === -1) {
                !new_data_format &&
                    tempMenuItems[activeItem].level_2[
                        activeItemModifier
                    ].level_3[activeItemModifierOption].ingredients.push(
                        newValue ?? (event.target.value as string)
                    );
                tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ].ingredients_uuid.push(
                    ingredients[newValue ?? event.target.value].uuid as string
                );
                tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ].qty.push(0);
            } else {
                !new_data_format &&
                    (tempMenuItems[activeItem].level_2[
                        activeItemModifier
                    ].level_3[activeItemModifierOption].ingredients[index] =
                        newValue ?? (event.target.value as string));
                tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ].ingredients_uuid[index] = ingredients[
                    newValue ?? event.target.value
                ].uuid as string;
                tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ].qty[index] = 0;
            }
        } else if (!!activeItem && !!activeItemModifier) {
            if (index === -1) {
                !new_data_format &&
                    tempMenuItems[activeItem].level_2[
                        activeItemModifier
                    ].ingredients.push(
                        newValue ?? (event.target.value as string)
                    );
                tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].ingredients_uuid.push(
                    ingredients[newValue ?? event.target.value].uuid as string
                );
                tempMenuItems[activeItem].level_2[activeItemModifier].qty.push(
                    0
                );
            } else {
                !new_data_format &&
                    (tempMenuItems[activeItem].level_2[
                        activeItemModifier
                    ].ingredients[index] =
                        newValue ?? (event.target.value as string));
                tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].ingredients_uuid[index] = ingredients[
                    newValue ?? event.target.value
                ].uuid as string;
                tempMenuItems[activeItem].level_2[activeItemModifier].qty[
                    index
                ] = 0;
            }
        } else {
            if (index === -1) {
                !new_data_format &&
                    tempMenuItems[activeItem].ingredients.push(
                        newValue ?? (event.target.value as string)
                    );
                tempMenuItems[activeItem].ingredients_uuid.push(
                    ingredients[newValue ?? event.target.value].uuid as string
                );
                tempMenuItems[activeItem].qty.push(0);
            } else {
                !new_data_format &&
                    (tempMenuItems[activeItem].ingredients[index] =
                        newValue ?? (event.target.value as string));
                tempMenuItems[activeItem].ingredients_uuid[index] = ingredients[
                    newValue ?? event.target.value
                ].uuid as string;
                tempMenuItems[activeItem].qty[index] = 0;
            }
        }
        setMenuItemsEgress({ ...tempMenuItems });
    };

    const handleSelectCategoryChange = (
        event: SelectChangeEvent,
        ingredient: string
    ) => {
        let tempIngredients = JSON.parse(JSON.stringify(ingredientsEgress));
        tempIngredients[ingredient].category = event.target.value as string;
        setIngredientsEgress({ ...tempIngredients });
    };

    const handleIngredientRefresh = (event?: any) => {
        getIngredients(concept)
            .then((results) => {
                process.env.REACT_APP_DEBUG === 'true' &&
                    console.log('Ingredients: ', results);
                setCategories(
                    results.categories.sort((a: string, b: string) => {
                        return a.localeCompare(b);
                    })
                );
                let sortedIngredients = Object.keys(results.ingredients)
                    .sort((a, b) => {
                        return a.localeCompare(b);
                    })
                    .reduce((obj: any, key: string) => {
                        obj[key] = results.ingredients[key];
                        return obj;
                    }, {});
                setIngredients(
                    sortedIngredients
                        ? JSON.parse(JSON.stringify(sortedIngredients))
                        : {}
                );
                setIngredientsEgress(
                    sortedIngredients
                        ? JSON.parse(JSON.stringify(sortedIngredients))
                        : {}
                );
                setUsageDates(
                    results.usage_date
                        ? JSON.parse(JSON.stringify(results.usage_date))
                        : {}
                );
                setLoadingSpinner(false);
            })
            .catch((err) => {
                console.error(err);
                setLoadingSpinner(false);
            });
    };

    const handleQtyChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => {
        let tempMenuItems = menuItemsEgress;
        if (
            !!activeItem &&
            !!activeItemModifier &&
            !!activeItemModifierOption
        ) {
            tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                activeItemModifierOption
            ].qty[index] = event.target.value;
        } else if (!!activeItem && !!activeItemModifier) {
            tempMenuItems[activeItem].level_2[activeItemModifier].qty[index] =
                event.target.value;
        } else {
            tempMenuItems[activeItem].qty[index] = event.target.value;
        }
        setMenuItemsEgress({ ...tempMenuItems });
    };

    const clearRecipeData = () => {
        let tempMenuItems = JSON.parse(JSON.stringify(menuItemsEgress));
        if (
            !!activeItem &&
            !!activeItemModifier &&
            !!activeItemModifierOption
        ) {
            tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                activeItemModifierOption
            ].qty = tempMenuItems[activeItem].level_2[
                activeItemModifier
            ].level_3[activeItemModifierOption].qty.filter(
                (_: any, idx: number) =>
                    ingredients[
                        Object.keys(ingredients).find(
                            (key: string) =>
                                ingredients[key].uuid ===
                                tempMenuItems[activeItem].level_2[
                                    activeItemModifier
                                ].level_3[activeItemModifierOption]
                                    .ingredients_uuid[idx]
                        ) ?? ''
                    ]?.category === 'Menu Items'
            );
            !new_data_format &&
                (tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ].ingredients = tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].level_3[activeItemModifierOption].ingredients.filter(
                    (ingredient: string) =>
                        ingredients[ingredient].category === 'Menu Items'
                ));
            tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                activeItemModifierOption
            ].ingredients_uuid = tempMenuItems[activeItem].level_2[
                activeItemModifier
            ].level_3[activeItemModifierOption].ingredients_uuid.filter(
                (ingredient_uuid: string) =>
                    Object.entries(ingredients).find(
                        (entry) => entry[1].uuid === ingredient_uuid
                    )![1].category === 'Menu Items'
            );
        } else if (!!activeItem && !!activeItemModifier) {
            tempMenuItems[activeItem].level_2[activeItemModifier].qty =
                tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].qty.filter(
                    (_: any, idx: number) =>
                        ingredients[
                            Object.keys(ingredients).find(
                                (key: string) =>
                                    ingredients[key].uuid ===
                                    tempMenuItems[activeItem].level_2[
                                        activeItemModifier
                                    ].ingredients_uuid[idx]
                            ) ?? ''
                        ]?.category === 'Menu Items'
                );
            !new_data_format &&
                (tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].ingredients = tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].ingredients.filter(
                    (ingredient: string) =>
                        ingredients[ingredient].category === 'Menu Items'
                ));
            tempMenuItems[activeItem].level_2[
                activeItemModifier
            ].ingredients_uuid = tempMenuItems[activeItem].level_2[
                activeItemModifier
            ].ingredients_uuid.filter(
                (ingredient_uuid: string) =>
                    Object.entries(ingredients).find(
                        (entry) => entry[1].uuid === ingredient_uuid
                    )![1].category === 'Menu Items'
            );
        } else {
            tempMenuItems[activeItem].qty = tempMenuItems[
                activeItem
            ].qty.filter(
                (_: any, idx: number) =>
                    ingredients[
                        Object.keys(ingredients).find(
                            (key: string) =>
                                ingredients[key].uuid ===
                                tempMenuItems[activeItem].ingredients_uuid[idx]
                        ) ?? ''
                    ]?.category === 'Menu Items'
            );
            !new_data_format &&
                (tempMenuItems[activeItem].ingredients = tempMenuItems[
                    activeItem
                ].ingredients.filter(
                    (ingredient: string) =>
                        ingredients[ingredient].category === 'Menu Items'
                ));
            tempMenuItems[activeItem].ingredients_uuid = tempMenuItems[
                activeItem
            ].ingredients_uuid.filter(
                (ingredient_uuid: string) =>
                    Object.entries(ingredients).find(
                        ([_, value]) => value.uuid === ingredient_uuid
                    )![1].category === 'Menu Items'
            );
        }
        setMenuItemsEgress(JSON.parse(JSON.stringify(tempMenuItems)));
        return tempMenuItems;
    };

    const copyIngredients = (
        target: any,
        sourceIng: any[],
        sourceUuid: any[],
        sourceQty: any[]
    ) => {
        if (!new_data_format) {
            target.ingredients.push.apply(target.ingredients, sourceIng);
        }
        target.ingredients_uuid.push.apply(target.ingredients_uuid, sourceUuid);
        target.qty.push.apply(target.qty, sourceQty);
    };

    const handleRecipeCopy = () => {
        var tempMenuItems = JSON.parse(JSON.stringify(clearRecipeData()));
        let target;

        if (
            !!activeItem &&
            !!activeItemModifier &&
            !!activeItemModifierOption
        ) {
            target =
                tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ];
        } else if (!!activeItem && !!activeItemModifier) {
            target = tempMenuItems[activeItem].level_2[activeItemModifier];
        } else {
            target = tempMenuItems[activeItem];
        }

        copyIngredients(target, copyRegIng, copyRegIngUuid, copyRegQty);

        setMenuItemsEgress(JSON.parse(JSON.stringify(tempMenuItems)));
    };

    const handleUnitChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ingredient: string
    ) => {
        let tempIngredients = JSON.parse(JSON.stringify(ingredientsEgress));
        tempIngredients[ingredient].units = event.target.value;
        setIngredientsEgress({ ...tempIngredients });
    };

    const handleNameChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setEditName(event.target.value);
    };

    const handleAccordianChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleModifierItemFormat = (
        newModifierItems: any[],
        newModifierItemsArr: any[]
    ) => {
        const emptyModifierIndex = newModifierItems.findIndex(
            (obj) => Object.keys(obj).length === 0
        );

        if (emptyModifierIndex !== -1) {
            const formattedModifierItems = [
                newModifierItems[emptyModifierIndex],
                ...newModifierItems.slice(0, emptyModifierIndex),
                ...newModifierItems.slice(emptyModifierIndex + 1),
            ];

            // Rearrange arrayB based on the empty object's index
            const formattedModifierItemsArr = [
                newModifierItemsArr[emptyModifierIndex],
                ...newModifierItemsArr.slice(0, emptyModifierIndex),
                ...newModifierItemsArr.slice(emptyModifierIndex + 1),
            ];

            return { formattedModifierItems, formattedModifierItemsArr };
        } else {
            // If no empty object in arrayA, create an empty object at the beginning of both arrays
            const formattedModifierItems = [{}, ...newModifierItems];
            const formattedModifierItemsArr = [[], ...newModifierItemsArr];

            return { formattedModifierItems, formattedModifierItemsArr };
        }
    };

    const handleOuterRecipeAccordianChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (pageLock) return;
            setActiveItem(panel);
            setActiveItemModifier('');
            setActiveItemModifierOption('');
            setMenuItemsEgress(JSON.parse(JSON.stringify(menuItems)));
            // Remove closing the accordian
            //setActiveItem(isExpanded ? panel : '');
            if (tabValue === 3) {
                let items = modifier_object[panel].Items;
                let newModifierItems: any[] = [{}];
                let newModifierItemsArr: string[][] = [[]];
                items.forEach((item: any, idx: number) => {
                    console.log('DEBUG::', item, panel);
                    let itemObj: any = {};
                    menuItems[item].level_2[panel].ingredients_uuid.forEach(
                        (uuid: string, idx_uuid: number) => {
                            itemObj[uuid] =
                                menuItems[item].level_2[panel].qty[idx_uuid];
                        }
                    );
                    for (
                        let index = 0;
                        index < newModifierItems.length;
                        index++
                    ) {
                        if (isEqual(newModifierItems[index], itemObj)) {
                            newModifierItemsArr[index].push(item);
                            return;
                        }
                    }
                    newModifierItems.push(itemObj);
                    newModifierItemsArr.push([item]);
                });
                const { formattedModifierItems, formattedModifierItemsArr } =
                    handleModifierItemFormat(
                        newModifierItems,
                        newModifierItemsArr
                    );
                setSelectedModIng([]);
                setModifierIngredients(
                    JSON.parse(JSON.stringify(formattedModifierItems))
                );
                setModifierIngredientsOnLoad(
                    JSON.parse(JSON.stringify(formattedModifierItems))
                );
                setModifierIngredientsArr(formattedModifierItemsArr);
                setModifierIngredientsArrOnLoad(formattedModifierItemsArr);
                setModifierIngredientsArrMiddleLoad(formattedModifierItemsArr);
            }
        };

    const handleMiddleRecipeAccordianChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (pageLock) return;
            setActiveItemModifier(panel);
            setActiveItemModifierOption('');
            setMenuItemsEgress(JSON.parse(JSON.stringify(menuItems)));
            // Remove closing the accordian
            //setActiveItem(isExpanded ? panel : '');
            if (tabValue === 3) {
                let items = modifier_object[activeItem].Options[panel];
                let newModifierItems: any[] = [];
                let newModifierItemsArr: string[][] = [];
                items.forEach((item: any, idx: number) => {
                    console.log('DEBUG::', item, panel);
                    let itemObj: any = {};
                    menuItems[item].level_2[activeItem].level_3[
                        panel
                    ].ingredients_uuid.forEach(
                        (uuid: string, idx_uuid: number) => {
                            itemObj[uuid] =
                                menuItems[item].level_2[activeItem].level_3[
                                    panel
                                ].qty[idx_uuid];
                        }
                    );
                    for (
                        let index = 0;
                        index < newModifierItems.length;
                        index++
                    ) {
                        if (isEqual(newModifierItems[index], itemObj)) {
                            newModifierItemsArr[index].push(item);
                            return;
                        }
                    }
                    newModifierItems.push(itemObj);
                    newModifierItemsArr.push([item]);
                });
                const { formattedModifierItems, formattedModifierItemsArr } =
                    handleModifierItemFormat(
                        newModifierItems,
                        newModifierItemsArr
                    );
                setSelectedModIng([]);
                setSelectedModIng([]);
                setModifierIngredients(
                    JSON.parse(JSON.stringify(formattedModifierItems))
                );
                setModifierIngredientsOnLoad(
                    JSON.parse(JSON.stringify(formattedModifierItems))
                );
                setModifierIngredientsArr(formattedModifierItemsArr);
                setModifierIngredientsArrOnLoad(formattedModifierItemsArr);
                setModifierIngredientsArrMiddleLoad(formattedModifierItemsArr);
            }
        };

    const handleInnerRecipeAccordianChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (pageLock) return;
            setActiveItemModifierOption(panel);
            setMenuItemsEgress(JSON.parse(JSON.stringify(menuItems)));
            // Remove closing the accordian
            //setActiveItem(isExpanded ? panel : '');
        };

    const handleIngredientRemoveFromItem = (index: number) => {
        let tempMenuItems = menuItemsEgress;
        if (
            !!activeItem &&
            !!activeItemModifier &&
            !!activeItemModifierOption
        ) {
            !new_data_format &&
                tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                    activeItemModifierOption
                ].ingredients.splice(index, 1);
            tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                activeItemModifierOption
            ].ingredients_uuid.splice(index, 1);
            tempMenuItems[activeItem].level_2[activeItemModifier].level_3[
                activeItemModifierOption
            ].qty.splice(index, 1);
        } else if (!!activeItem && !!activeItemModifier) {
            !new_data_format &&
                tempMenuItems[activeItem].level_2[
                    activeItemModifier
                ].ingredients.splice(index, 1);
            tempMenuItems[activeItem].level_2[
                activeItemModifier
            ].ingredients_uuid.splice(index, 1);
            tempMenuItems[activeItem].level_2[activeItemModifier].qty.splice(
                index,
                1
            );
        } else {
            !new_data_format &&
                tempMenuItems[activeItem].ingredients.splice(index, 1);
            tempMenuItems[activeItem].ingredients_uuid.splice(index, 1);
            tempMenuItems[activeItem].qty.splice(index, 1);
        }
        setMenuItemsEgress({ ...tempMenuItems });
    };

    const addNewIngredient = (category: string) => {
        let ingredientsCopy = ingredients;
        let newKey = 'New Forecastable Item';
        if (Object.keys(ingredients).indexOf('New Forecastable Item') !== -1) {
            let counter = 1;
            while (
                Object.keys(ingredients).indexOf(
                    `New Forecastable Item (${counter})`
                ) !== -1
            ) {
                counter += 1;
            }
            newKey = `New Forecastable Item (${counter})`;
        }

        ingredientsCopy[newKey] = {
            category: category,
            units: 'each',
            uuid: uuid(),
        };
        ingredientsCopy = Object.keys(ingredientsCopy)
            .sort((a, b) => {
                return a.localeCompare(b);
            })
            .reduce((obj: any, key: string) => {
                obj[key] = ingredientsCopy[key];
                return obj;
            }, {});
        setIngredients({ ...ingredientsCopy });
        setActiveItem(newKey);

        let newIng: any = {};
        newIng[newKey] = ingredientsCopy[newKey];

        updateIngredients({
            concept: idToIdx,
            ingredients: newIng,
        });

        setEditName(newKey);
        setIngredientsEgress({ ...ingredientsCopy });
        setDialogState(1);
        setOpenDialog(true);
    };

    const handleIngredientDeletion = () => {
        let tempIngredients = JSON.parse(JSON.stringify(ingredientsEgress));
        let tempPrepLists = JSON.parse(JSON.stringify(prepLists));
        let tempMenuItems = JSON.parse(JSON.stringify(menuItems));
        let deletedItemUUID = tempIngredients[activeItem].uuid;
        let impactedPrepItems = [];
        let impactedMenuItems: any = {};
        Object.keys(tempPrepLists).forEach((list) => {
            Object.keys(tempPrepLists[list]).forEach((section) => {
                tempPrepLists[list][section].forEach((item: any) => {
                    if (item.ingredient_uuid === deletedItemUUID) {
                        impactedPrepItems.push(item.item_name);
                        let index = tempPrepLists[list][section].indexOf(item);
                        if (index > -1) {
                            tempPrepLists[list][section].splice(index, 1);
                        }
                    }
                });
            });
        });
        Object.keys(tempMenuItems).forEach((menuItem) => {
            if (
                tempMenuItems[menuItem].ingredients_uuid.includes(
                    deletedItemUUID
                )
            ) {
                impactedMenuItems[menuItem] = tempMenuItems[menuItem];
                let indexToRemove =
                    impactedMenuItems[menuItem].ingredients_uuid.indexOf(
                        deletedItemUUID
                    );
                impactedMenuItems[menuItem].ingredients_uuid.splice(
                    indexToRemove,
                    1
                );
                impactedMenuItems[menuItem].qty.splice(indexToRemove, 1);
            }
            if (tempMenuItems[menuItem].level_2) {
                Object.keys(tempMenuItems[menuItem].level_2).forEach(
                    (subMenuItem) => {
                        if (
                            tempMenuItems[menuItem].level_2[
                                subMenuItem
                            ].ingredients_uuid.includes(deletedItemUUID)
                        ) {
                            if (!impactedMenuItems.hasOwnProperty(menuItem)) {
                                impactedMenuItems[menuItem] =
                                    tempMenuItems[menuItem];
                            }
                            let indexToRemove =
                                impactedMenuItems[menuItem].level_2[
                                    subMenuItem
                                ].ingredients_uuid.indexOf(deletedItemUUID);
                            impactedMenuItems[menuItem].level_2[
                                subMenuItem
                            ].ingredients_uuid.splice(indexToRemove, 1);
                            impactedMenuItems[menuItem].level_2[
                                subMenuItem
                            ].qty.splice(indexToRemove, 1);
                        }
                        if (
                            tempMenuItems[menuItem].level_2[subMenuItem].level_3
                        ) {
                            Object.keys(
                                tempMenuItems[menuItem].level_2[subMenuItem]
                                    .level_3
                            ).forEach((superSubMenuItem) => {
                                if (
                                    tempMenuItems[menuItem].level_2[
                                        subMenuItem
                                    ].level_3[
                                        superSubMenuItem
                                    ].ingredients_uuid.includes(deletedItemUUID)
                                ) {
                                    if (
                                        !impactedMenuItems.hasOwnProperty(
                                            menuItem
                                        )
                                    ) {
                                        impactedMenuItems[menuItem] =
                                            tempMenuItems[menuItem];
                                    }
                                    let indexToRemove =
                                        impactedMenuItems[menuItem].level_2[
                                            subMenuItem
                                        ].level_3[
                                            superSubMenuItem
                                        ].ingredients_uuid.indexOf(
                                            deletedItemUUID
                                        );
                                    impactedMenuItems[menuItem].level_2[
                                        subMenuItem
                                    ].level_3[
                                        superSubMenuItem
                                    ].ingredients_uuid.splice(indexToRemove, 1);
                                    impactedMenuItems[menuItem].level_2[
                                        subMenuItem
                                    ].level_3[superSubMenuItem].qty.splice(
                                        indexToRemove,
                                        1
                                    );
                                }
                            });
                        }
                    }
                );
            }
        });
        delete tempIngredients[activeItem];
        (new_data_format
            ? deleteIngredients({
                  concept: idToIdx,
                  ingredients: [activeItem],
              }).then(() => {
                  //   if (Object.keys(impactedMenuItems).length !== 0) {
                  updateMenuItems({
                      id: idToIdx,
                      menuItems: JSON.parse(JSON.stringify(impactedMenuItems)),
                  });
                  //   }
                  //   if (Object.keys(tempPrepLists).length !== 0) {
                  updatePrepSheets({
                      id: idToIdx,
                      prepLists: JSON.parse(JSON.stringify(tempPrepLists)),
                  });
                  //   }
              })
            : postMenu({
                  id: idToIdx,
                  ingredients: tempIngredients,
                  menuItems: menuItems,
                  categories: categories,
                  prepLists: tempPrepLists,
              })
        )
            .then(() => {
                setToastState({
                    toastMessage: 'Item deleted!',
                    toastType: 'success',
                });
            })
            .catch((err) => {
                console.error(err);
                setToastState({
                    toastMessage: 'Failed to delete item.',
                    toastType: 'error',
                });
                return;
            });
        setActiveItem('');
        setIngredients(JSON.parse(JSON.stringify(tempIngredients)));
        setIngredientsEgress(JSON.parse(JSON.stringify(tempIngredients)));
        let NewMenuItem = Object.assign({}, tempMenuItems, impactedMenuItems);
        setMenuItems(JSON.parse(JSON.stringify(NewMenuItem)));
        setMenuItemsEgress(JSON.parse(JSON.stringify(NewMenuItem)));
        const newPrepListsCopy = JSON.parse(JSON.stringify(tempPrepLists));
        dispatch(setPrepLists(newPrepListsCopy));
    };

    const handleEgressPromotion = () => {
        let handlePrepListCorrection: boolean =
            ingredientsEgress[activeItem].units !==
            ingredients[activeItem].units;
        let prepListChange: boolean = false;
        let tempIngredients = JSON.parse(JSON.stringify(ingredientsEgress));
        let tempPrepLists = JSON.parse(JSON.stringify(prepLists));
        if (editName.trim() !== activeItem) {
            tempIngredients[editName.trim()] = tempIngredients[activeItem];
            delete tempIngredients[activeItem];
        }
        if (handlePrepListCorrection) {
            Object.keys(tempPrepLists).forEach((PrepList: string) => {
                Object.keys(tempPrepLists[PrepList]).forEach(
                    (prepSubList: string) => {
                        tempPrepLists[PrepList][prepSubList].forEach(
                            (prepItem: any, idx: number) => {
                                if (
                                    prepItem.ingredient_uuid ===
                                        ingredientsEgress[activeItem].uuid &&
                                    prepItem.new_unit === ''
                                ) {
                                    tempPrepLists[PrepList][prepSubList][
                                        idx
                                    ].units =
                                        ingredientsEgress[activeItem].units;
                                    prepListChange = true;
                                }
                            }
                        );
                    }
                );
            });
        }
        tempIngredients = Object.keys(tempIngredients)
            .sort((a, b) => {
                return a.localeCompare(b);
            })
            .reduce((obj: any, key: string) => {
                obj[key] = tempIngredients[key];
                return obj;
            }, {});
        var editedIngredients: any = {};
        editedIngredients[editName.trim()] = tempIngredients[editName.trim()];
        if (new_data_format) {
            if (editName.trim() === activeItem) {
                updateIngredients({
                    concept: idToIdx,
                    ingredients: editedIngredients,
                }).then(() => {
                    if (prepListChange) {
                        updatePrepSheets({
                            id: idToIdx,
                            prepLists: JSON.parse(
                                JSON.stringify(tempPrepLists)
                            ),
                        }).then(() => {
                            const newPrepListsCopy = JSON.parse(
                                JSON.stringify(tempPrepLists)
                            );
                            dispatch(setPrepLists(newPrepListsCopy));
                        });
                    }
                });
            } else {
                !!activeItem
                    ? deleteIngredients({
                          concept: idToIdx,
                          ingredients: [activeItem],
                      }).then(() => {
                          updateIngredients({
                              concept: idToIdx,
                              ingredients: editedIngredients,
                          }).then(() => {
                              if (prepListChange) {
                                  updatePrepSheets({
                                      id: idToIdx,
                                      prepLists: JSON.parse(
                                          JSON.stringify(tempPrepLists)
                                      ),
                                  }).then(() => {
                                      const newPrepListsCopy = JSON.parse(
                                          JSON.stringify(tempPrepLists)
                                      );
                                      dispatch(setPrepLists(newPrepListsCopy));
                                  });
                              }
                          });
                      })
                    : updateIngredients({
                          concept: idToIdx,
                          ingredients: editedIngredients,
                      }).then(() => {
                          if (prepListChange) {
                              updatePrepSheets({
                                  id: idToIdx,
                                  prepLists: JSON.parse(
                                      JSON.stringify(tempPrepLists)
                                  ),
                              }).then(() => {
                                  const newPrepListsCopy = JSON.parse(
                                      JSON.stringify(tempPrepLists)
                                  );
                                  dispatch(setPrepLists(newPrepListsCopy));
                              });
                          }
                      });
            }
        } else {
            postMenu({
                id: idToIdx,
                ingredients: tempIngredients,
                menuItems: menuItems,
                categories: categories,
                prepLists: prepLists,
            })
                .then(() => {
                    setToastState({
                        toastMessage: 'Changes saved!',
                        toastType: 'success',
                    });
                })
                .catch((err) => {
                    console.error(err);
                    setToastState({
                        toastMessage: 'Changes failed to save',
                        toastType: 'error',
                    });
                    return;
                });
        }
        setActiveItem(editName.trim());
        setIngredients(JSON.parse(JSON.stringify(tempIngredients)));
        setOpenDialog(false);
    };

    const handleEgressDemotion = () => {
        setIngredientsEgress({ ...ingredients });
        setOpenDialog(false);
    };

    return (
        <Box
            width="100%"
            height="100vh"
            display="flex"
            sx={{ flexDirection: 'column' }}
        >
            {openDialog &&
                tabValue === 0 &&
                dialogState === 1 &&
                ingredientDialogs.NewMenuItem(
                    openDialog,
                    dialogState,
                    setOpenDialog,
                    editName,
                    handleNameChange,
                    ingredients,
                    ingredientsEgress,
                    activeItem,
                    handleUnitChange,
                    handleSelectCategoryChange,
                    expanded,
                    categories,
                    handleIngredientDeletion,
                    handleEgressDemotion,
                    handleEgressPromotion,
                    duplicateError,
                    setDuplicateError
                )}
            {openDialog &&
                tabValue === 0 &&
                dialogState === 2 &&
                ingredientDialogs.AddToPOSItems(
                    idToIdx,
                    openDialog,
                    dialogState,
                    setOpenDialog,
                    editName,
                    handleNameChange,
                    ingredients,
                    ingredientsEgress,
                    activeItem,
                    handleUnitChange,
                    handleSelectCategoryChange,
                    expanded,
                    categories,
                    handleIngredientDeletion,
                    handleEgressDemotion,
                    handleEgressPromotion,
                    duplicateError,
                    setDuplicateError,
                    menuItems,
                    modifiedMenuItems,
                    setModifiedMenuItems,
                    modifiedQtyValue,
                    setModifiedQtyValue,
                    setMenuItems,
                    setMenuItemsEgress,
                    dialogAutocompleteInputValue,
                    setDialogAutocompleteInputValue
                )}
            {tabValue === 2 && (
                <>
                    <preplistDialogs.EditEntryPopup
                        categories={categories}
                        ingredients={ingredients}
                        addToPrepList={addToPrepList}
                    />
                    <preplistDialogs.SideSummary />
                    <preplistDialogs.NewSection addSection={addSection} />
                    <preplistDialogs.EditSection addSection={addSection} />
                    <preplistDialogs.NameEntry />
                    <preplistDialogs.EntryMapper
                        categories={categories}
                        ingredients={ingredients}
                    />
                    <preplistDialogs.Frequency />
                    <preplistDialogs.Units ingredients={ingredients} />
                    <preplistDialogs.PrepSchedule />
                    <preplistDialogs.Scheduler />
                    <preplistDialogs.UnitConversion ingredients={ingredients} />
                    <preplistDialogs.Yield ingredients={ingredients} />
                    <preplistDialogs.ShelfLife addToPrepList={addToPrepList} />
                    <preplistDialogs.PrepTime ingredients={ingredients} />
                    <preplistDialogs.FinishEntry />
                </>
            )}
            {openDialog &&
                tabValue === 3 &&
                dialogState === 1 &&
                modifierDialogs.ModifyModList(
                    openDialog,
                    dialogState,
                    setOpenDialog,
                    editName,
                    handleNameChange,
                    ingredients,
                    ingredientsEgress,
                    activeItem,
                    handleUnitChange,
                    handleSelectCategoryChange,
                    expanded,
                    categories,
                    handleIngredientDeletion,
                    handleEgressDemotion,
                    handleEgressPromotion,
                    duplicateError,
                    setDuplicateError,
                    modifierIngredientsArr,
                    modifierIngredientsArrOnLoad,
                    modifierIngredientsArrMiddleLoad,
                    modifierIngredients,
                    setModifierIngredientsArr,
                    setModifierIngredientsArrOnLoad,
                    setModifierIngredientsArrMiddleLoad,
                    setModifierIngredients
                )}
            {openDialog &&
                genericDialogs.UnsavedChanges(
                    openDialog,
                    dialogState,
                    setOpenDialog,
                    setDialogState,
                    setNewTabValue,
                    nextTabValue,
                    setNextTabValue
                )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    width: '100%',
                    height: '100vh',
                }}
            >
                <Box
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        paddingLeft: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs"
                    >
                        <Tab
                            label="Forecastable Items"
                            {...a11yProps(0)}
                            onClick={() => {
                                handleIngredientRefresh();
                            }}
                        />
                        <Tab
                            label="POS Items"
                            {...a11yProps(1)}
                        />
                        <Tab
                            label="Prep Sheets"
                            {...a11yProps(2)}
                        />
                        {patricks_secret_lair && (
                            <Tab
                                label="POS Modifier Groups"
                                {...a11yProps(3)}
                            />
                        )}
                    </Tabs>
                </Box>
                {user_details &&
                user_details.concepts &&
                user_details.concepts.includes(concept) ? (
                    <>
                        <TabPanel
                            value={tabValue}
                            index={0}
                        >
                            <IngredientTab
                                tabValue={tabValue}
                                categories={categories}
                                expanded={expanded}
                                handleAccordianChange={handleAccordianChange}
                                ingredients={ingredients}
                                activeItem={activeItem}
                                menuItems={menuItems}
                                setActiveItem={setActiveItem}
                                setActiveItemModifier={setActiveItemModifier}
                                setActiveItemModifierOption={
                                    setActiveItemModifierOption
                                }
                                setTabValue={setTabValue}
                                addNewIngredient={addNewIngredient}
                                setEditName={setEditName}
                                setIngredientsEgress={setIngredientsEgress}
                                setOpenDialog={setOpenDialog}
                                setDialogState={setDialogState}
                                store={store}
                                usageDates={usageDates}
                            />
                        </TabPanel>
                        <TabPanel
                            value={tabValue}
                            index={1}
                        >
                            <RecipeTab
                                tabValue={tabValue}
                                menuItems={menuItems}
                                menuItemsEgress={menuItemsEgress}
                                activeItem={activeItem}
                                handleOuterRecipeAccordianChange={
                                    handleOuterRecipeAccordianChange
                                }
                                activeItemModifier={activeItemModifier}
                                activeItemModifierOption={
                                    activeItemModifierOption
                                }
                                ingredients={ingredients}
                                handleMiddleRecipeAccordianChange={
                                    handleMiddleRecipeAccordianChange
                                }
                                handleInnerRecipeAccordianChange={
                                    handleInnerRecipeAccordianChange
                                }
                                setCopyRegIng={setCopyRegIng}
                                setCopyRegIngUuid={setCopyRegIngUuid}
                                setCopyRegQty={setCopyRegQty}
                                copyRegIng={copyRegIng}
                                copyRegIngUuid={copyRegIngUuid}
                                copyRegQty={copyRegQty}
                                handleRecipeCopy={handleRecipeCopy}
                                handleSelectChange={handleSelectChange}
                                handleQtyChange={handleQtyChange}
                                handleIngredientRemoveFromItem={
                                    handleIngredientRemoveFromItem
                                }
                                setActiveItem={setActiveItem}
                                setMenuItems={setMenuItems}
                                setMenuItemsEgress={setMenuItemsEgress}
                                idToIdx={idToIdx}
                                categories={categories}
                                prepLists={prepLists}
                                filter={filter}
                                setFilter={setFilter}
                                filteredMenuItems={filteredMenuItems}
                                setToastState={setToastState}
                                searchBarRef={searchBarRef}
                                setPageLock={setPageLock}
                            />
                        </TabPanel>
                        <TabPanel
                            value={tabValue}
                            index={2}
                        >
                            <PrepListTab />
                        </TabPanel>
                        {patricks_secret_lair && (
                            <TabPanel
                                value={tabValue}
                                index={3}
                            >
                                <RecipeModifierTab
                                    tabValue={tabValue}
                                    ingredients={ingredients}
                                    menuItems={menuItems}
                                    setMenuItems={setMenuItems}
                                    setMenuItemsEgress={setMenuItemsEgress}
                                    activeItem={activeItem}
                                    activeItemModifier={activeItemModifier}
                                    handleOuterRecipeAccordianChange={
                                        handleOuterRecipeAccordianChange
                                    }
                                    handleMiddleRecipeAccordianChange={
                                        handleMiddleRecipeAccordianChange
                                    }
                                    modifierIngredients={modifierIngredients}
                                    modifierIngredientsOnLoad={
                                        modifierIngredientsOnLoad
                                    }
                                    modifierIngredientsArr={
                                        modifierIngredientsArr
                                    }
                                    modifierIngredientsArrOnLoad={
                                        modifierIngredientsArrOnLoad
                                    }
                                    setModifierIngredients={
                                        setModifierIngredients
                                    }
                                    setModifierIngredientsOnLoad={
                                        setModifierIngredientsOnLoad
                                    }
                                    setModifierIngredientsArr={
                                        setModifierIngredientsArr
                                    }
                                    setModifierIngredientsArrOnLoad={
                                        setModifierIngredientsArrOnLoad
                                    }
                                    setModifierIngredientsArrMiddleLoad={
                                        setModifierIngredientsArrMiddleLoad
                                    }
                                    idToIdx={idToIdx}
                                    modifier_object={modifier_object}
                                    setActiveItem={setActiveItem}
                                    setActiveItemModifier={
                                        setActiveItemModifier
                                    }
                                    setActiveItemModifierOption={
                                        setActiveItemModifierOption
                                    }
                                    setTabValue={setTabValue}
                                    setOpenDialog={setOpenDialog}
                                    selectedModIng={selectedModIng}
                                    setSelectedModIng={setSelectedModIng}
                                />
                            </TabPanel>
                        )}
                    </>
                ) : loadingSpinner ? (
                    <Box
                        sx={{
                            m: 4,
                            flexGrow: 1,
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}
                    >
                        <CircularProgress />
                    </Box>
                ) : (
                    <ConceptNoAccess />
                )}
            </Box>
        </Box>
    );
}
