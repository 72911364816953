import { RecipesInterface, initialRecipesState } from './recipesState';

const recipesReducer = (
    state: Partial<RecipesInterface> = initialRecipesState,
    action: any
) => {
    switch (action.type) {
        case 'SET_PREP_LISTS':
            return {
                ...state,
                prepLists: action.payload.prepLists,
            };
        case 'SET_FOCUSED_PREP_SECTION':
            return {
                ...state,
                selectedPrepSection: action.payload.focusedPrepSection,
                focusedPrepSection: action.payload.focusedPrepSection,
                dialogState: action.payload.dialogState ?? -1,
                openDialog: true,
            };
        case 'UPDATE_FOCUSED_PREP_SECTION':
            return {
                ...state,
                focusedPrepSection: action.payload.focusedPrepSection,
            };
        case 'CLEAR_FOCUSED_PREP_SECTION':
            return {
                ...state,
                selectedPrepSection: initialRecipesState.selectedPrepSection,
                focusedPrepSection: initialRecipesState.focusedPrepSection,
                dialogState: initialRecipesState.dialogState,
                openDialog: initialRecipesState.openDialog,
            };
        case 'SET_FOCUSED_PREP_ITEM':
            return {
                ...state,
                focusedPrepItem: action.payload.focusedPrepItem,
                dialogState: -2,
                openDialog: true,
            };
        case 'UPDATE_FOCUSED_PREP_ITEM':
            return {
                ...state,
                focusedPrepItem: {
                    ...state.focusedPrepItem,
                    ...action.payload.focusedPrepItem,
                },
                dialogState: action.payload.dialogState ?? state.dialogState,
                openDialog: action.payload.openDialog ?? state.openDialog,
            };
        case 'CLEAR_FOCUSED_PREP_ITEM':
            return {
                ...state,
                focusedPrepItem: {
                    ...initialRecipesState.focusedPrepItem,
                },
                dialogState: initialRecipesState.dialogState,
                openDialog: initialRecipesState.openDialog,
            };
        case 'NEW_FOCUSED_PREP_ITEM':
            return {
                ...state,
                focusedPrepItem: {
                    ...initialRecipesState.focusedPrepItem,
                    section: action.payload.section,
                },
                dialogState: initialRecipesState.dialogState,
                openDialog: true,
            };
        case 'SET_ACTIVE_ITEM':
            return {
                ...state,
                activeItem: action.payload.activeItem,
            };
        case 'SET_SELECTED_PREP_LIST':
            return {
                ...state,
                selectedPrepList: action.payload.selectedPrepList,
            };
        case 'SET_PREP_LICENCE':
            return {
                ...state,
                prepLicence: action.payload.prepLicence,
            };
        default:
            return state;
    }
};

export default recipesReducer;
